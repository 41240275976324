import React from "react";
import {
  Alert,
  CircularProgress,
  FormControl,
  MenuItem,
  Collapse,
  Select,
  Typography,
} from "@mui/material";
import XFA_API, {
  Application,
  GoogleGroup,
  MicrosoftGroup,
} from "../../API/XFA_API";

import { useTranslation } from "react-i18next";
import { XfaSwitch } from "../../General/XfaSwitch";

export const GroupsSelector: React.FC<{
  application: Application;
  setApplication: (application: Application) => void;
}> = (props) => {
  const [groupsOptions, setGroupsOptions] = React.useState<
    MicrosoftGroup[] | GoogleGroup[] | undefined
  >(undefined);
  const [error, setError] = React.useState<string | undefined>();

  const [value, setValue] = React.useState<string[]>([]);
  React.useEffect(() => {
    let newValue: string[] = [];
    if (props.application.MicrosoftConnection) {
      if (props.application.MicrosoftConnection.OnlyGroups) {
        //populate the groupsOptions when not already populated
        if (!groupsOptions) {
          setGroupsOptions(props.application.MicrosoftConnection.OnlyGroups);
        }
        for (const group of props.application.MicrosoftConnection.OnlyGroups) {
          newValue.push(group.ID);
        }
      }
      setValue(newValue);
    }
    if (props.application.GoogleConnection) {
      if (props.application.GoogleConnection.OnlyGroups) {
        //populate the groupsOptions when not already populated
        if (!groupsOptions) {
          setGroupsOptions(props.application.GoogleConnection.OnlyGroups);
        }
        for (const group of props.application.GoogleConnection.OnlyGroups) {
          newValue.push(group.ID);
        }
      }
      setValue(newValue);
    }
  }, [
    props.application,
    props.application.MicrosoftConnection?.OnlyGroups,
    props.application.GoogleConnection?.OnlyGroups,
  ]);

  const handleChange = (event: any) => {
    const newValue = event.target.value as string[];
    setValue(newValue);
    const application = { ...props.application };
    if (application.MicrosoftConnection) {
      application.MicrosoftConnection.OnlyGroups = groupsOptions?.filter(
        (group) => newValue.includes(group.ID),
      );
    }
    if (application.GoogleConnection) {
      application.GoogleConnection.OnlyGroups = groupsOptions?.filter((group) =>
        newValue.includes(group.ID),
      );
    }
    props.setApplication(application);
  };

  const [loading, setLoading] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const [enabled, setEnabled] = React.useState<boolean>(
    (props.application.MicrosoftConnection?.OnlyGroups &&
      props.application.MicrosoftConnection?.OnlyGroups.length > 0) ||
      (props.application.GoogleConnection?.OnlyGroups &&
        props.application.GoogleConnection?.OnlyGroups?.length > 0)
      ? true
      : false,
  );

  return (
    <FormControl fullWidth={true} size="small">
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Typography variant="setting">
            {t("applications.microsoft.groups")}
          </Typography>
          <Typography variant="instructions">
            {t("applications.microsoft.groupsDescription")}
          </Typography>
        </div>
        <div>
          <XfaSwitch
            checked={enabled}
            disableRipple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEnabled(event.target.checked);
              if (!event.target.checked) {
                const application = { ...props.application };
                if (application.MicrosoftConnection) {
                  application.MicrosoftConnection.OnlyGroups = [];
                }
                if (application.GoogleConnection) {
                  application.GoogleConnection.OnlyGroups = [];
                }
                props.setApplication(application);
              }
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: 8 }}></div>
      <Collapse in={enabled}>
        <FormControl fullWidth={true} size="small">
          <Select
            value={value}
            onChange={handleChange}
            multiple={true}
            style={{
              marginLeft: 16,
            }}
            onOpen={() => {
              setLoading(true);
              XFA_API.getGroupsDiscoveryIntegration(
                props.application.OrganizationID,
                props.application.ApplicationID,
              )
                .then((response) => {
                  setGroupsOptions(response);
                  setLoading(false);
                })
                .catch((error) => {
                  setError(error.message);
                  setLoading(false);
                });
            }}
          >
            {loading ? (
              <MenuItem disabled={true}>
                <CircularProgress size={16}></CircularProgress>
              </MenuItem>
            ) : (
              groupsOptions &&
              groupsOptions.map((group) => (
                <MenuItem key={group.ID} value={group.ID}>
                  {group.Name}
                </MenuItem>
              ))
            )}
          </Select>
          {error && <Alert severity="error">{error}</Alert>}
        </FormControl>
      </Collapse>
    </FormControl>
  );
};

export default GroupsSelector;
