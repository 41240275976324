import { API, Auth } from "aws-amplify";
import { getRecentDevices } from "../Users/UserUtils";

export interface Shadow {
  email: string;
  devices: Device[];
}

export interface Device {
  device_id: string;
  timestamp_submitted: number;
  os_name?: string;
  os_version?: string;
  os_flavour?: string;
  os_autoupdate?: boolean;
  os_uptodate?: boolean;
  user?: UserInformation;
  computer_name?: ComputerName;
  android_security_level?: string;
  manufacturer?: string;
  model?: string;
  browser_name?: string;
  browser_version?: string;
  browser_uptodate?: boolean;
  chrome_version?: string;
  chrome_uptodate?: boolean;
  firefox_version?: string;
  firefox_uptodate?: boolean;
  edge_version?: string;
  edge_uptodate?: boolean;
  safari_version?: string;
  safari_uptodate?: boolean;
  antivirus_enabled?: boolean;
  passwordmanager?: PasswordManager;
  diskencryption_supported?: boolean;
  diskencryption_active?: boolean;
  nativeclient_installed?: boolean;
  authentication_active?: boolean;
  biometrics?: string;
  managed?: boolean;
  xfa_version?: string;
  merged_device_ids?: string[];
  merged_into_device_id?: string;
  email: string;
  connect?: boolean;
  skip?: boolean;
  unsupported?: boolean;
  discovered?: boolean;
  google_mobile_device_ids?: string[];
  google_cloud_identity_device_ids?: string[];
  microsoft_device_ids?: string[];
  okta_discovered?: boolean;
  timestamp_last_notified: number;
}

export interface ComputerName {
  name: string;
}

export interface UserInformation {
  username?: string;
  full_name?: string;
}

export interface PasswordManager {
  lastpass?: boolean;
  onepassword?: boolean;
  dashlane?: boolean;
  bitwarden?: boolean;
  keepass?: boolean;
  nordpass?: boolean;
  protonpass?: boolean;
  icloud_passwords?: boolean;
  dropbox_passwords?: boolean;
  passbolt?: boolean;
  enpass?: boolean;
  avast_passwords?: boolean;
  avira_passwords?: boolean;
}

export interface Role {
  email: string;
  roleId: string;
  roleType: string;
  organization: Organization;
  intercom_hmac: string;
  blocked_access: boolean;
}

export interface Organization {
  organization_id: string;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  vat: string;
  billing_email: string;
  alpha_features?: boolean;
  beta_features?: boolean;
  logo_url?: string;
  notify_discovered_devices?: boolean;
  notify_discovered_devices_frequency_in_days?: number;
  notify_unsafe_devices?: boolean;
  notify_unsafe_devices_frequency_in_days?: number;
  notify_only_groups?: ExternalGroup[];
  default_policy_id?: string;
}

export interface FullOrganization {
  organization_id: string;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  vat: string;
  billing_email: string;
  deleted?: boolean;
  active_users: ActiveUsers;
  settings: BillingSettings;
  invoice: Invoice;
  logo_url?: string;
  notify_discovered_devices?: boolean;
  notify_discovered_devices_frequency_in_days?: number;
  notify_unsafe_devices?: boolean;
  notify_unsafe_devices_frequency_in_days?: number;
  notify_only_groups?: ExternalGroup[];
  default_policy_id?: string;
  currentMRR: number;
  contractedMRR: number;
  potentialMRR: number;
  currentMRRLastMonth: number;
  contractedMRRLastMonth: number;
  potentialMRRLastMonth: number;
  timestamp: number;
}

export interface ActiveUsers {
  current_insight: number;
  last_insight: number;
  current_connect: number;
  last_connect: number;
  current_user_count: number;
  last_user_count: number;
}

export interface Invitation {
  timestamp: string;
}

export interface User {
  organization_id: string;
  email: string;
  invitations?: Invitation[];
  devices?: Device[];
  role: string;
}

export interface DashboardUser {
  organization_id: string;
  email: string;
  timestamp: string;
  notifications_weekly_report: boolean;
  notifications_email_reporting: boolean;
  notifications_email_marketing: boolean;
  notifications_email_product_updates: boolean;
  notifications_email_product_assistance: boolean;
  notifications_admin_preview_device_risk: boolean;
}

export interface Invoice {
  Number: number;
  OrganizationID: string;
  Date: string;
  Users: number;
  ConnectUsers: number;
  Amount: number;
  Status: string;
  Timestamp: number;
}

export interface Application {
  Enabled?: boolean;
  ApplicationID: string;
  PolicyID?: string;
  Name: string;
  IconUrl?: string;
  OrganizationID: string;
  Policies: Policies;
  TestKey?: string;
  AllowedApplicationDomainRegexes?: string[];
  PublicCerts?: string[];
  SAMLConfiguration?: SAMLConfiguration;
  OAuthConfiguration?: OAuthConfiguration;
  ClientSecret?: string;
  SSOUrl?: string;
  Issuer?: string;
  SAMLMetadataUrl: string;
  FilteredEmail?: string[];
  FilterMobile?: boolean;
  FilterDesktop?: boolean;
  Type?: string;
  UniquePurpose?: string;
  GoogleConnection?: GoogleConnection;
  MicrosoftConnection?: MicrosoftConnection;
  OktaConnection?: OktaConnection;
  EnableMfa?: boolean;
  EnableMfaAutoApproveFirstDevice?: boolean;
}

export interface ApplicationCreationRequest extends Application {
  Code?: string;
}

export interface Policies {
  name?: string;
  policy_id: string;
  organization_id: string;
  installed?: boolean;
  unsupported?: boolean;
  skip?: boolean;
  skip_mobile?: boolean;
  skip_desktop?: boolean;
  os?: VersionPolicy;
  os_autoupdate?: EnabledPolicy;
  browser?: VersionPolicy;
  disk_encryption?: EnabledPolicy;
  screen_lock?: EnabledPolicy;
  antivirus?: EnabledPolicy;
  password_manager?: EnabledPolicy;
}

export interface VersionPolicy {
  WarnTime?: number;
  BlockTime?: number;
  older_supported_major_versions?: DisabledPolicy;
  beta_versions?: DisabledPolicy;
}

export interface EnabledPolicy {
  warn?: boolean;
  block?: boolean;
}

export interface DisabledPolicy {
  dont_warn?: boolean;
  dont_block?: boolean;
}

export class DefaultPolicies implements Policies {
  policy_id: string = "";
  organization_id: string = "";
  skip: boolean = true;
  unsupported: boolean = true;
  installed: boolean = true;
  os: VersionPolicy = {
    WarnTime: 0,
  };
  browser: VersionPolicy = {
    WarnTime: 0,
  };
  os_autoupdate: EnabledPolicy = {
    warn: true,
  };
  disk_encryption: EnabledPolicy = {
    warn: true,
  };
  screen_lock: EnabledPolicy = {
    warn: true,
  };
  antivirus: EnabledPolicy = {
    warn: true,
  };
}

export interface SAMLConfiguration {
  Enabled: boolean;
  ServiceProviderAssertionConsumerServiceURL: string;
  IdentityProviderSSOURL: string;
  IdentityProviderIssuer: string;
  IdentityProviderPublicCertificate: string;
  SignResponse: boolean;
  FactorOnly?: boolean;
  FactorOnlyAudience?: string;
  ForceReauthentication?: boolean;
  PreventPassiveLogin?: boolean;
  AzureForceAccountChooser?: boolean;
  GoogleForceAccountChooser?: boolean;
}

export interface OAuthConfiguration {
  Enabled: boolean;
  RedirectURLToClient?: string;
  TokenEndpointOfProvider?: string;
}

export interface Count {
  count: number;
  connect_count: number;
}

export interface BillingSettings {
  OrganizationID: string;
  InsightPrice: number;
  ConnectPrice: number;
  FreeDevices: number;
  TrialEndDate: string;
  PerUserPrice?: number;
}

export interface GoogleConnection {
  Scopes: string[];
  Email?: string;
  ErrorMobileDevicesAPI?: string;
  ErrorCloudIdentityAPI?: string;
  OnlyUnmanaged?: boolean; //Only discover unmanaged devices
  OnlyGroups?: GoogleGroup[]; //Only discover devices from users in these groups (no groups means all groups)
  ExcludeDesktop?: boolean; //exclude desktop devices
  ExcludeMobile?: boolean; //exclude mobile devices
}

export interface MicrosoftConnection {
  Scopes: string[];
  Error?: string;
  OnlyUnmanaged?: boolean; //Only discover unmanaged devices
  OnlyGroups?: MicrosoftGroup[]; //Only discover devices from users in these groups (no groups means all groups)
  ExcludeDesktop?: boolean; //exclude desktop devices
  ExcludeMobile?: boolean; //exclude mobile devices
}

export interface OktaConnection {
  OktaDomain: string;
  ClientID: string;
  ClientSecret: string;
}

// GoogleGroup holds the name and id of a group to filter discovered devices
export interface GoogleGroup {
  Name: string;
  ID: string;
}

// GoogleGroup holds the name and id of a group to filter discovered devices
export interface ExternalGroup {
  name: string;
  id: string;
}

// MicrosoftGroup holds the name and id of a group to filter discovered devices
export interface MicrosoftGroup {
  Name: string;
  ID: string;
}

export interface ConnectionError {
  GoogleMobileDevicsAPIError?: string;
  GoogleCloudIdentityAPIError?: string;
  MicrosoftAPIError?: string;
}

export interface MfaDevice {
  deviceId: string;
  id: string;
}

const XFA_API = {
  getAllDevices: async () => {
    try {
      const result = await API.get("XFA_API", `devices`, {});
      if (result) {
        if (result.devices) {
          return result.devices as Device[];
        } else {
          return [] as Device[];
        }
      }
      console.log("getAllDevices call not successful");
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
  getDevices: async (organizationId: string, policyId?: string | undefined) => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices?organization_id=${organizationId}${policyId ? `&policyId=${policyId}` : ""}`,
        {},
      );
      if (result) {
        if (result.devices) {
          return result.devices as Device[];
        } else {
          return [] as Device[];
        }
      }
      console.log("getAllDevices call not successful");
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
  getDevicesForUser: async (
    organizationID: string,
    email: string,
    policyId?: string | undefined,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/devices?email=${email}${policyId ? `&policyId=${policyId}` : ""}`,
        {},
      );

      if (result) {
        return result as Device[];
      } else {
        return [] as Device[];
      }
    } catch (err) {
      throw new Error(
        `Sending verification request for devices associated with ${email} not successful`,
      );
    }
  },
  getGroupedDevices: async (
    organizationId: string,
    startKey: string | undefined,
    policyId?: string | undefined,
  ): Promise<
    { shadows: Shadow[]; lastEvaluatedKey: string | undefined } | undefined
  > => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices/grouped?organization_id=${organizationId}${
          startKey ? `&exclusive_start_key=${startKey}` : ""
        }${policyId ? `&policyId=${policyId}` : ""}`,
        {},
      );
      if (result) {
        if (result.shadows) {
          const shadows: Shadow[] = Object.keys(result.shadows).map(
            (email) => ({
              email,
              devices: result.shadows[email],
            }),
          );
          return { shadows, lastEvaluatedKey: result.last_evaluated_key };
        } else {
          return { shadows: [], lastEvaluatedKey: undefined };
        }
      }
      console.log("getGroupedDevices call not successful");
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
  getDeviceCount: async (organizationId: string, date: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices/count?organization_id=${organizationId}&date=${date}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
        connect_count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve device count");
    }
  },
  getDeviceCountCustomTimeframe: async (
    organizationId: string,
    startDate: string,
    endDate: string | undefined,
    onlyShadows: boolean,
  ) => {
    try {
      let queryParams = `start_date_unix=${startDate}`;
      if (endDate) {
        queryParams += `&end_date_unix=${endDate}`;
      }
      const result = await API.get(
        "XFA_API",
        `devices/count?organization_id=${organizationId}&${queryParams}&only_shadows=${onlyShadows}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
        connect_count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve device count");
    }
  },
  getUserCount: async (organizationId: string, date: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices/count-users?organization_id=${organizationId}&date=${date}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve user count");
    }
  },
  getUserCountCustomTimeframe: async (
    organizationId: string,
    startDate: string,
    endDate: string | undefined,
    onlyShadows: boolean,
  ) => {
    try {
      let queryParams = `start_date_unix=${startDate}`;
      if (endDate) {
        queryParams += `&end_date_unix=${endDate}`;
      }
      const result = await API.get(
        "XFA_API",
        `devices/count-users?organization_id=${organizationId}&${queryParams}&only_shadows=${onlyShadows}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve user count");
    }
  },
  getRoles: async () => {
    try {
      const result = await API.get("XFA_API", `roles`, {});
      if (result) {
        return result as Role[];
      } else {
        return [] as Role[];
      }
    } catch (err) {
      console.log(err);
    }
  },
  createOrganization: async (
    name: string,
  ): Promise<Organization | undefined> => {
    try {
      const result = await API.post("XFA_API", `organizations`, {
        body: { name: name },
      });

      if (result) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        const refreshedSession = await new Promise((resolve, reject) => {
          cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            (err: any, session: any) => {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                resolve(session);
              }
            },
          );
        });

        return result as Organization;
      }
      return undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  },

  getOrganizations: async () => {
    try {
      const result = await API.get("XFA_API", `organizations`, {});
      if (result) {
        return result as FullOrganization[];
      }
      console.log("retrieving organizations call not successful");
    } catch (err) {
      console.log(err);
    }
  },
  getOrganizationFull: async (organizationId: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/full`,
        {},
      );
      if (result) {
        return result as FullOrganization;
      }
      console.log("retrieving organization call not successful");
    } catch (err) {
      console.log(err);
    }
  },
  updateOrganization: async (organizationId: string, org: Organization) => {
    try {
      await API.put("XFA_API", `organizations/${organizationId}`, {
        body: org,
      });
    } catch (err) {
      console.log(err);
    }
  },
  inviteUser: async (organizationID: string, email: string) => {
    try {
      const result = await API.post("XFA_API", `invitations`, {
        body: { organization_id: organizationID, email: email },
      });
      if (result) {
        return;
      }
      throw Error("Inviting user not successful");
    } catch (err) {
      throw Error("Inviting user not successful");
    }
  },
  resendInviteUser: async (organizationID: string, email: string) => {
    try {
      await API.put("XFA_API", `invitations`, {
        body: { organization_id: organizationID, email: email },
      });
    } catch (err) {
      throw Error("Re-inviting user not successful");
    }
  },
  getUsers: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/users`,
        {},
      );
      if (result) {
        if (result.users) {
          return result.users as User[];
        } else {
          return [] as User[];
        }
      }
      throw Error("Requesting users of organization not successful");
    } catch (err) {
      throw Error("Requesting users of organization not successful");
    }
  },
  getDashboardUsers: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/dashboard-users`,
        {},
      );
      if (result) {
        if (result) {
          return result as DashboardUser[];
        } else {
          return [] as DashboardUser[];
        }
      }
      throw Error("Requesting dashboard users of organization not successful");
    } catch (err) {
      throw Error("Requesting dashboard users of organization not successful");
    }
  },
  updateDashboardUser: async (organizationID: string, user: DashboardUser) => {
    try {
      const result = await API.put(
        "XFA_API",
        `organizations/${organizationID}/dashboard-users`,
        { body: user },
      );
    } catch (err) {
      throw Error("Updating dashboard user of organization not successful");
    }
  },
  deleteUser: async (
    organizationID: string,
    deviceIds: string[] | undefined,
    email: string,
  ) => {
    try {
      await API.del("XFA_API", `affiliations-admin`, {
        body: {
          organization_id: organizationID,
          device_ids: deviceIds,
          email: email,
        },
      });
    } catch (err) {
      throw Error("Deleting user not successful");
    }
  },
  inviteAdminUser: async (organizationID: string, email: string) => {
    try {
      await API.post("XFA_API", `organizations/${organizationID}/users`, {
        body: { invitee: email },
      });
    } catch (err) {
      throw Error("Inviting admin not successful");
    }
  },
  deleteAdminUser: async (organizationID: string, email: string) => {
    try {
      await API.del("XFA_API", `organizations/${organizationID}/users`, {
        body: { email: email },
      });
    } catch (err) {
      throw Error("Deleting admin not successful");
    }
  },
  getInvoices: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/invoices`,
        {},
      );
      if (result) {
        if (result.invoices) {
          return result.invoices as Invoice[];
        } else {
          return [] as Invoice[];
        }
      }
      throw Error("Requesting invoices of organization not successful");
    } catch (err) {
      throw Error("Requesting invoices of organization not successful");
    }
  },
  getInvoice: async (
    organizationID: string,
    date: string,
    invoiceNr: string,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/invoices/${date}/${invoiceNr}`,
        {},
      );
      if (result) {
        return result;
      }
      throw Error("Requesting invoice not successful");
    } catch (err) {
      throw Error("Requesting invoice not successful");
    }
  },
  addPaymentMethod: async (
    organizationID: string,
    cardToken: string | undefined,
  ) => {
    const path = cardToken
      ? `payment-method/${organizationID}?cardToken=${cardToken}`
      : `payment-method/${organizationID}`;
    try {
      const result = await API.post("XFA_API", path, {});
      if (result) {
        return result.Checkout;
      }
      throw Error("Adding payment method not successful");
    } catch (err) {
      throw Error("Adding payment method not successful");
    }
  },
  updatePaymentMethod: async (organizationID: string) => {
    try {
      const result = await API.put(
        "XFA_API",
        `payment-method/${organizationID}`,
        {},
      );
      if (result) {
        return result.Checkout;
      }
      throw Error("Updating payment method not successful");
    } catch (err) {
      throw Error("Updating payment method not successful");
    }
  },
  getPaymentMethod: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `payment-method/${organizationID}`,
        {},
      );
      if (result) {
        return result;
      }
      throw Error("Getting payment method not successful");
    } catch (err) {
      throw Error("Getting payment method not successful");
    }
  },
  getApplications: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications`,
        {},
      );
      if (result) {
        if (result.Applications) {
          return result.Applications as Application[];
        } else {
          return [] as Application[];
        }
      }
      throw Error("Requesting applications of organization not successful");
    } catch (err) {
      throw Error("Requesting applications of organization not successful");
    }
  },
  getDashboardDemo: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications/dashboard-demo`,
        {},
      );
      if (result) {
        return result as Application;
      }
      throw Error("Requesting dashboard demo application not successful");
    } catch (err) {
      throw Error("Requesting dashboard application demo not successful");
    }
  },
  createApplication: async (
    organizationID: string,
    application: Application,
  ) => {
    try {
      if (
        application.Policies === undefined &&
        application.Type !== "Google" &&
        application.Type !== "Microsoft" &&
        application.Type !== "OktaDiscovery"
      ) {
        application.Policies = new DefaultPolicies();
      }

      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/applications`,
        {
          body: application,
        },
      );
      if (result) {
        return result;
      }
      throw Error("Creating application not successful");
    } catch (err) {
      throw Error("Creating application not successful");
    }
  },
  updateApplication: async (application: Application) => {
    try {
      const result = await API.put(
        "XFA_API",
        `organizations/${application.OrganizationID}/applications/${application.ApplicationID}`,
        { body: application },
      );
      if (result) {
        return result;
      }
      throw Error("Updating application not successful");
    } catch (err) {
      throw Error("Updating application not successful");
    }
  },
  deleteApplication: async (application: Application) => {
    try {
      await API.del(
        "XFA_API",
        `organizations/${application.OrganizationID}/applications/${application.ApplicationID}`,
        {},
      );
    } catch (err) {
      throw Error("Deleting application not successful");
    }
  },
  connectVanta: async (
    code: string,
    organizationID: string,
    redirectUri: string,
  ) => {
    try {
      await API.post("XFA_API", `connect/vanta`, {
        body: {
          code: code,
          organization_id: organizationID,
          redirect_uri: redirectUri,
        },
      });
    } catch (err) {
      throw Error("Connecting Vanta not successful");
    }
  },
  connectThoropass: async (
    code: string,
    code_verifier: string,
    organizationID: string,
    redirectUri: string,
  ) => {
    try {
      await API.post("XFA_API", `connect/thoropass`, {
        body: {
          code: code,
          code_verifier: code_verifier,
          organization_id: organizationID,
          redirect_uri: redirectUri,
        },
      });
    } catch (err) {
      throw Error("Connecting Thoropass not successful");
    }
  },
  getBillingSettings: async (
    organizationID: string,
  ): Promise<BillingSettings> => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/billing-settings`,
        {},
      );
      if (result) {
        return result as BillingSettings;
      }
      throw Error("Getting billing settings not successful");
    } catch (err) {
      throw Error("Getting billing settings not successful");
    }
  },
  updateBillingSettings: async (
    organizationID: string,
    billingSettings: BillingSettings,
  ): Promise<void> => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/billing-settings`,
        {
          body: billingSettings,
        },
      );
    } catch (err) {
      throw Error("Getting billing settings not successful");
    }
  },
  connectApplication: async (
    organizationID: string,
    applicationID: string,
    code: string,
    redirectUri: string,
  ) => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/connect`,
        {
          body: {
            Code: code,
            RedirectURI: redirectUri,
          },
        },
      );
      if (result) {
        return result as ConnectionError;
      }
    } catch (err) {
      throw Error("Connecting application not successful");
    }
  },
  triggerDiscovery: async (organizationID: string, applicationID: string) => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/trigger-discovery`,
        {},
      );

      if (result) {
        return result as ConnectionError;
      }
    } catch (err) {
      console.error(err);
    }
  },
  getGroupsDiscoveryIntegration: async (
    organizationID: string,
    applicationID: string,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/groups`,
        {},
      );

      if (result) {
        return result;
      }
    } catch (err) {
      console.error(err);
    }
  },
  getMfaDevices: async (organizationID: string, email: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/users/${email}/credential`,
        {},
      );
      if (result) {
        return (result as MfaDevice[]).map((device) => device.deviceId);
      }
      throw Error("Getting mfa devices not successful");
    } catch (err) {
      throw Error("Getting mfa devices not successful");
    }
  },
  deleteMfa: async (
    organizationID: string,
    deviceID: string | undefined,
    email: string,
  ) => {
    try {
      let path =
        deviceID == undefined
          ? `organizations/${organizationID}/users/${email}`
          : `organizations/${organizationID}/users/${email}/credential/${deviceID}`;
      await API.del("XFA_API", path, {});
    } catch (err) {
      if ((err as { response: { status: number } }).response.status == 404) {
        console.error(err);
        return;
      }
      throw Error("Deleting mfa not successful");
    }
  },
  deleteOrganization: async (organizationID: string) => {
    try {
      await API.del("XFA_API", `organizations/${organizationID}`, {});
    } catch (err) {
      throw Error("Deleting organization not successful");
    }
  },
  createPolicy: async (organizationId: string, policy: Policies) => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationId}/policies`,
        {
          body: policy,
        },
      );
      if (result) {
        return result;
      }
      throw Error("Creating policy not successful");
    } catch (err) {
      throw Error("Creating policy not successful");
    }
  },
  getPolicies: async (organizationId: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/policies`,
        {},
      );
      if (result) {
        return result as Policies[];
      } else {
        return [];
      }
    } catch (err) {
      throw Error("Retrieving policies not successful");
    }
  },
  getPolicy: async (organizationId: string, policyId: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/policies/${policyId}`,
        {},
      );
      if (result) {
        return result as Policies;
      }
      throw Error("Retrieving policy not successful");
    } catch (err) {
      throw Error("Retrieving policy not successful");
    }
  },
  updatePolicy: async (
    organizationId: string,
    policyId: string,
    policy: Policies,
  ) => {
    try {
      await API.put(
        "XFA_API",
        `organizations/${organizationId}/policies/${policyId}`,
        {
          body: policy,
        },
      );
    } catch (err) {
      throw Error("Updating policy not successful");
    }
  },
  deletePolicy: async (organizationId: string, policyId: string) => {
    try {
      await API.del(
        "XFA_API",
        `organizations/${organizationId}/policies/${policyId}`,
        {},
      );
    } catch (err) {
      throw Error("Deleting policy not successful");
    }
  },
  sendVerificationEmail: async (
    organizationID: string,
    deviceID: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices/${deviceID}/send-verification-request`;
      if (policyId) {
        url += `?policyId=${policyId}`;
      }
      await API.post("XFA_API", url, {});
    } catch (err) {
      throw Error("Sending verification email not successful");
    }
  },
  sendRiskEmail: async (
    organizationID: string,
    deviceID: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices/${deviceID}/send-risk-request`;
      if (policyId) {
        url += `?policyId=${policyId}`;
      }
      await API.post("XFA_API", url, {});
    } catch (err) {
      console.error(err);
      throw Error("Sending Risk email not successful ");
    }
  },
  sendVerificationRequestForUser: async (
    organizationID: string,
    email: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices?email=${email}`;
      if (policyId) {
        url += `&policyId=${policyId}`;
      }
      const response = await API.get("XFA_API", url, {});

      const devices = response as Device[];
      await Promise.all(
        getRecentDevices(devices).map((device) => {
          if (device.os_name) {
            XFA_API.sendVerificationEmail(organizationID, device.device_id);
          }
        }),
      );
    } catch (err) {
      throw new Error(
        `Sending verification request for devices associated with ${email} not successful`,
      );
    }
  },
  sendRiskRequestForUser: async (
    organizationID: string,
    email: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices?email=${email}`;
      if (policyId) {
        url += `&policyId=${policyId}`;
      }

      const response = await API.get("XFA_API", url, {});

      const devices = response as Device[];

      await Promise.all(
        getRecentDevices(devices).map((device) => {
          if (device.os_name) {
            XFA_API.sendRiskEmail(organizationID, device.device_id);
          }
        }),
      );
    } catch (err) {
      throw new Error(
        `Sending verification request for devices associated with ${email} not successful`,
      );
    }
  },
};

export default XFA_API;
