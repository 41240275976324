import React, { useEffect, useState } from "react";
import "./OrganizationTab.css";
import { Alert, CircularProgress, Divider } from "@mui/material";
import SettingsSection from "./SettingsSection";
import XFA_API, { DashboardUser } from "../API/XFA_API";
import { useTranslation } from "react-i18next";
import XfaSwitchWithDescription from "../General/XfaSwitchWithDescription";

export interface NotificationsTabProps {
  organizationID: string;
  user: DashboardUser;
}

const NotificationsTab: React.FC<NotificationsTabProps> = ({
  organizationID,
  user,
}) => {
  const { t } = useTranslation();
  const [emailReporting, setEmailReporting] = useState<boolean>(
    user.notifications_email_reporting,
  );
  const [emailMarketing, setEmailMarketing] = useState<boolean>(
    user.notifications_email_marketing,
  );
  const [emailProductUpdates, setEmailProductUpdates] = useState<boolean>(
    user.notifications_email_product_updates,
  );
  const [emailProductAssistance, setEmailProductAssistant] = useState<boolean>(
    user.notifications_email_product_assistance,
  );
  const [adminPreviewDeviceRisk, setAdminPreviewDeviceRisk] = useState<boolean>(
    user.notifications_admin_preview_device_risk,
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>();

  useEffect(() => {
    const updateDashboardUser = async () => {
      setLoading(true);
      user.notifications_admin_preview_device_risk = adminPreviewDeviceRisk;
      user.notifications_email_marketing = emailMarketing;
      user.notifications_email_product_assistance = emailProductAssistance;
      user.notifications_email_product_updates = emailProductUpdates;
      user.notifications_email_reporting = emailReporting;

      try {
        await XFA_API.updateDashboardUser(organizationID, user);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    updateDashboardUser();
  }, [
    emailReporting,
    emailMarketing,
    emailProductUpdates,
    emailProductAssistance,
    adminPreviewDeviceRisk,
  ]);

  return (
    <div className="container">
      {error && (
        <div style={{ marginBottom: 15 }}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      <div className="organization-section">
        <div className="organization-section-container">
          <div className="organization-title-section">
            <div className="inline-settings-header">
              {t("settings.notifications.title")}
            </div>
            <div className="inline-settings-description">
              {t("settings.notifications.description")}
            </div>
          </div>
          {loading && (
            <CircularProgress
              size="20px"
              style={{ marginRight: "10px", color: "white" }}
            />
          )}
        </div>
        <Divider />
        <SettingsSection
          title={t("settings.notifications.email")}
          description={t("settings.notifications.emailDescription")}
        >
          <div className="logo-upload-section">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <XfaSwitchWithDescription
                label={t("settings.notifications.reporting")}
                checked={emailReporting}
                onChange={(checked) => {
                  setEmailReporting(checked);
                }}
              />
              <XfaSwitchWithDescription
                label={t("settings.notifications.marketing")}
                checked={emailMarketing}
                onChange={(checked) => {
                  setEmailMarketing(checked);
                }}
              />
              <XfaSwitchWithDescription
                label={t("settings.notifications.productUpdates")}
                checked={emailProductUpdates}
                onChange={(checked) => {
                  setEmailProductUpdates(checked);
                }}
              />
              <XfaSwitchWithDescription
                label={t("settings.notifications.productAssistance")}
                checked={emailProductAssistance}
                onChange={(checked) => {
                  setEmailProductAssistant(checked);
                }}
              />
            </div>
          </div>
        </SettingsSection>
        <SettingsSection
          title={t("settings.notifications.adminPreview")}
          description={t("settings.notifications.adminPreviewDescription")}
        >
          <div className="logo-upload-section">
            <XfaSwitchWithDescription
              label={t("settings.notifications.risk")}
              checked={adminPreviewDeviceRisk}
              onChange={(checked) => {
                setAdminPreviewDeviceRisk(checked);
              }}
            />
          </div>
        </SettingsSection>
      </div>
    </div>
  );
};

export default NotificationsTab;
