import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "../Applications/Applications.css";
import XFA_API, {
  FullOrganization,
  ExternalGroup,
  Organization,
  Policies,
  Role,
} from "../API/XFA_API";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import VerificationMailPreview from "../../images/verification-mail-preview.png";
import RiskMailPreview from "../../images/risk-mail-preview.png";

import BooleanSetting from "../Applications/NewOrEditPage/BooleanSetting";
import CloseIcon from "@mui/icons-material/Close";
import NavigationService from "../../utils/NavigationService";
import { SettingsBar } from "./SettingsBar";
import { getRecentDevices } from "../Users/UserUtils";
import { XfaSwitch } from "../General/XfaSwitch";
import { isDiscoveryApplication } from "../../utils";

interface AwarenessProps {
  role: Role;
}

const Awareness: React.FC<AwarenessProps> = (props: AwarenessProps) => {
  return (
    <Routes>
      <Route path="" element={<Overview {...props} />} />
    </Routes>
  );
};

interface OverviewProps {
  role: Role;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [organization, setOrganization] = React.useState<FullOrganization>();

  const [autoVerifyMail, setAutoVerifyMail] = useState<boolean | undefined>(
    undefined,
  );
  const [autoVerifyMailDays, setAutoVerifyMailDays] = useState<
    number | undefined
  >(undefined);
  const [autoRiskMail, setAutoRiskMail] = useState<boolean | undefined>(
    undefined,
  );
  const [autoRiskMailDays, setAutoRiskMailDays] = useState<number | undefined>(
    undefined,
  );
  const [hasDevices, setHasDevices] = useState<boolean>(false);
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] =
    useState<boolean>(false);
  const [learnMoreRiskDialogOpen, setLearnMoreRiskDialogOpen] =
    useState<boolean>(false);
  const [verifyMailSent, setVerifyMailSent] = useState<boolean>(false);
  const [sendingVerifyMail, setSendingVerifyMail] = useState<boolean>(false);
  const [riskMailSent, setRiskMailSent] = useState<boolean>(false);
  const [sendingRiskMail, setSendingRiskMail] = useState<boolean>(false);
  const [policies, setPolicies] = useState<Policies[]>([]);
  const [groups, setGroups] = useState<ExternalGroup[]>([]);
  const [value, setValue] = React.useState<string[]>([]);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [loadingGroups, setLoadingGroups] = React.useState<boolean>(true);

  const refreshOrganization = React.useCallback(async () => {
    setLoading(true);
    try {
      const org = await XFA_API.getOrganizationFull(
        props.role.organization.organization_id,
      );
      if (!org) {
        setError(t("organization.error"));
        return;
      }
      setOrganization(org);
      setAutoVerifyMail(org.notify_discovered_devices);
      setAutoRiskMail(org.notify_unsafe_devices);
      const groups = org.notify_only_groups?.map((group) => group.id) ?? [];
      setValue(groups);
      setChecked(groups.length > 0);
      setAutoVerifyMailDays(
        org.notify_discovered_devices_frequency_in_days ?? 3,
      );
      setAutoRiskMailDays(org.notify_unsafe_devices_frequency_in_days ?? 3);
    } catch (error: any) {
      setError(t("organization.error"));
    }
    setLoading(false);
  }, [t, props.role.organization.organization_id]);

  const refreshDevices = React.useCallback(async () => {
    setLoading(true);
    try {
      const devices = await XFA_API.getDevicesForUser(
        props.role.organization.organization_id,
        props.role.email,
      );
      const recentDevices = getRecentDevices(devices);
      setHasDevices((recentDevices && recentDevices.length > 0) ?? false);
    } catch (error: any) {
      setError(t("devices.error"));
    }
    setLoading(false);
  }, []);

  const refreshPolicies = React.useCallback(async () => {
    setLoading(true);
    try {
      const policies = await XFA_API.getPolicies(
        props.role.organization.organization_id,
      );
      setPolicies(policies ?? []);
    } catch (error: any) {
      setError(t("policies.error"));
    }
    setLoading(false);
  }, []);

  const handleChange = (event: any) => {
    const selectedGroups = event.target.value as string[];
    setValue(selectedGroups);

    updateOrganization(
      undefined,
      undefined,
      undefined,
      undefined,
      selectedGroups,
      groups,
    );
  };

  const updateOrganization = useCallback(
    async (
      autoVerifyMailChange: boolean | undefined,
      autoRiskMailChange: boolean | undefined,
      autoVerifyMailDaysChange: number | undefined,
      autoRiskMailDaysChange: number | undefined,
      selectedGroups: string[] | undefined,
      groups: ExternalGroup[],
    ) => {
      const updatedOrganization: Organization = { ...props.role.organization };

      // Auto verify
      if (
        autoVerifyMailChange !== undefined &&
        autoVerifyMailChange !==
          props.role.organization.notify_discovered_devices
      ) {
        updatedOrganization.notify_discovered_devices = autoVerifyMailChange;
      }

      if (
        autoVerifyMailDaysChange !== undefined &&
        autoVerifyMailDaysChange !==
          props.role.organization.notify_discovered_devices_frequency_in_days
      ) {
        updatedOrganization.notify_discovered_devices_frequency_in_days =
          autoVerifyMailDaysChange;
      }

      //Auto risk
      if (
        autoRiskMailChange !== undefined &&
        autoRiskMailChange !== props.role.organization.notify_unsafe_devices
      ) {
        updatedOrganization.notify_unsafe_devices = autoRiskMailChange;
      }

      if (
        autoRiskMailDaysChange !== undefined &&
        autoRiskMailDaysChange !==
          props.role.organization.notify_unsafe_devices_frequency_in_days
      ) {
        updatedOrganization.notify_unsafe_devices_frequency_in_days =
          autoRiskMailDaysChange;
      }

      if (selectedGroups) {
        updatedOrganization.notify_only_groups = groups.filter((group) => {
          return selectedGroups.includes(group.id);
        });
      }

      if (Object.keys(updatedOrganization).length > 0) {
        await XFA_API.updateOrganization(
          props.role.organization.organization_id,
          updatedOrganization,
        );
      }
    },
    [props.role.organization],
  );

  const refreshGroups = useCallback(async () => {
    setLoadingGroups(true);
    try {
      const applications = await XFA_API.getApplications(
        props.role.organization.organization_id,
      );
      const filteredApplications = applications.filter((app) =>
        isDiscoveryApplication(app),
      );
      const discoveryGroups: ExternalGroup[] = [];
      for (const application of filteredApplications) {
        const groups = await XFA_API.getGroupsDiscoveryIntegration(
          props.role.organization.organization_id,
          application.ApplicationID,
        );
        if (groups.length > 0) {
          for (const group of groups) {
            const newGroup: ExternalGroup = {
              name: `${application.Name} - ${group.Name}`,
              id: group.ID,
            };
            discoveryGroups.push(newGroup);
          }
        }
      }
      setLoadingGroups(false);
      setGroups(discoveryGroups);
    } catch (error: any) {
      setLoadingGroups(false);
      setError(t("applications.error"));
    }
  }, [t, props.role.organization.organization_id]);

  const handleAutoVerifyMailChange = (autoVerifyMailChange: boolean) => {
    setAutoVerifyMail(autoVerifyMailChange);
    updateOrganization(
      autoVerifyMailChange,
      undefined,
      undefined,
      undefined,
      undefined,
      groups,
    );
  };

  const handleAutoVerifyMailDaysChange = (days: number) => {
    setAutoVerifyMailDays(days);
    updateOrganization(
      undefined,
      undefined,
      days,
      undefined,
      undefined,
      groups,
    );
  };

  const handleAutoRiskMailChange = (autoRiskMailChange: boolean) => {
    setAutoRiskMail(autoRiskMailChange);
    updateOrganization(
      undefined,
      autoRiskMailChange,
      undefined,
      undefined,
      undefined,
      groups,
    );
  };

  const handleAutoRiskMailDaysChange = (days: number) => {
    setAutoRiskMailDays(days);
    updateOrganization(
      undefined,
      undefined,
      undefined,
      days,
      undefined,
      groups,
    );
  };

  const SendVerifyTestEmail = async () => {
    localStorage.setItem("verifyMail", JSON.stringify(true));
    setSendingVerifyMail(true);

    XFA_API.sendVerificationRequestForUser(
      props.role.organization.organization_id,
      props.role.email,
    )
      .then(() => {
        setVerifyMailSent(true);
        setTimeout(() => {
          //remove confirmation message after 3 seconds
          setVerifyMailSent(false);
        }, 3000);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setSendingVerifyMail(false);
      });
  };

  const SendRiskTestEmail = async () => {
    setSendingRiskMail(true);
    XFA_API.sendRiskRequestForUser(
      props.role.organization.organization_id,
      props.role.email,
    )
      .then(() => {
        setRiskMailSent(true);
        setTimeout(() => {
          //remove confirmation message after 3 seconds
          setRiskMailSent(false);
        }, 3000);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setSendingRiskMail(false);
      });
  };

  const handleLearnMoreClick = (risk: boolean) => {
    if (risk) {
      setLearnMoreRiskDialogOpen(true);
      return;
    }
    localStorage.setItem("verifyMail", JSON.stringify(true));
    setLearnMoreDialogOpen(true);
  };

  const handleLearnMoreDialogClose = () => {
    setLearnMoreDialogOpen(false);
    setLearnMoreRiskDialogOpen(false);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const showLearnMore = searchParams.get("showLearnMore");
    if (showLearnMore === "true") {
      setLearnMoreDialogOpen(true);
      localStorage.setItem("verifyMail", JSON.stringify(true));
    }
    refreshOrganization();
    refreshDevices();
    refreshPolicies();
    refreshGroups();
  }, [location.search, refreshOrganization]);

  return (
    <div>
      <div className="flex">
        <Typography variant="pagetitle" color="primary">
          {t("navigation.awareness")}
        </Typography>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div className="w-full" data-cy="applications">
        {error && (
          <div style={{ marginBottom: 8, marginTop: 16, width: "50%" }}>
            <Alert
              severity="error"
              onClose={() => {
                setError("");
              }}
            >
              {error}
            </Alert>
          </div>
        )}
        {loading ? (
          <div className="center">
            <CircularProgress />{" "}
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <div
                className="section awarenessCard"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="setting" style={{ marginTop: "8px" }}>
                  {t("verify.groups")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    alignItems: "end",
                  }}
                >
                  <XfaSwitch
                    checked={checked}
                    disableRipple
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!event.target.checked) {
                        setValue([]);
                        updateOrganization(
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          [],
                          groups,
                        );
                      }
                      setChecked(event.target.checked);
                    }}
                  />
                  {loadingGroups && checked ? (
                    <CircularProgress
                      size={24}
                      style={{ marginTop: 16, marginRight: 12 }}
                    ></CircularProgress>
                  ) : (
                    <FormControl
                      fullWidth={true}
                      size="small"
                      style={{ display: checked ? "block" : "none" }}
                    >
                      <Select
                        value={value}
                        onChange={handleChange}
                        multiple={true}
                        style={{
                          marginTop: 16,
                          width: "100%",
                        }}
                      >
                        {loading ? (
                          <MenuItem disabled={true}>
                            <CircularProgress size={16}></CircularProgress>
                          </MenuItem>
                        ) : (
                          groups &&
                          groups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                              {group.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      {error && <Alert severity="error">{error}</Alert>}
                    </FormControl>
                  )}
                </div>
              </div>

              <div className="section awarenessCard">
                <BooleanSetting
                  label={t("verify.autoVerifyMail")}
                  explanation={t("verify.autoVerifyMailDescription")}
                  value={autoVerifyMail ?? false}
                  onChange={handleAutoVerifyMailChange}
                />
                <SettingsBar
                  isCollapsibleVisible={autoVerifyMail ?? false}
                  daysInput={autoVerifyMailDays}
                  setDaysInput={handleAutoVerifyMailDaysChange}
                  isTestYourselfDisabled={!hasDevices}
                  testYourselfTooltip={
                    !hasDevices
                      ? t("verify.testNoDevices")
                      : t("verify.testVerifiedDevices")
                  }
                  handleLearnMoreClick={() => handleLearnMoreClick(false)}
                  handleTestYourselfClick={SendVerifyTestEmail}
                  sendingMail={sendingVerifyMail}
                  mailSent={verifyMailSent}
                />
              </div>
              <div className="section awarenessCard">
                <BooleanSetting
                  label={t("verify.autoRiskMail")}
                  explanation={t("verify.autoRiskMailDescription")}
                  value={autoRiskMail ?? false}
                  onChange={handleAutoRiskMailChange}
                />
                <SettingsBar
                  isCollapsibleVisible={autoRiskMail ?? false}
                  daysInput={autoRiskMailDays}
                  setDaysInput={handleAutoRiskMailDaysChange}
                  isTestYourselfDisabled={!hasDevices}
                  testYourselfTooltip={
                    !hasDevices
                      ? t("verify.testNoDevices")
                      : t("verify.testRiskDevices")
                  }
                  handleLearnMoreClick={() => handleLearnMoreClick(true)}
                  handleTestYourselfClick={SendRiskTestEmail}
                  sendingMail={sendingRiskMail}
                  mailSent={riskMailSent}
                />
              </div>
            </div>
            {policies.length > 0 && (
              <div
                className="section awarenessCard"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="setting" style={{ margin: "auto 0" }}>
                  {t("verify.defaultPolicyTitle")}
                </Typography>
                <Button
                  variant="text"
                  style={{ flexShrink: 0 }}
                  onClick={() => {
                    NavigationService.navigateToPolicies();
                  }}
                >
                  {t("verify.defaultPolicyButton")}
                </Button>
              </div>
            )}
          </>
        )}
        <Dialog
          open={learnMoreDialogOpen || learnMoreRiskDialogOpen}
          onClose={handleLearnMoreDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            {learnMoreDialogOpen
              ? t("verify.learnMoreDialogTitle")
              : t("verify.learnMoreDialogRiskTitle")}
            <IconButton
              aria-label="close"
              onClick={handleLearnMoreDialogClose}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {learnMoreDialogOpen
                ? t("verify.learnMoreDialogDescription1")
                : t("verify.learnMoreDialogRiskDescription1")}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              {learnMoreDialogOpen
                ? t("verify.learnMoreDialogDescription2")
                : t("verify.learnMoreDialogRiskDescription2")}
            </Typography>
            {learnMoreDialogOpen && (
              <Typography variant="body1" style={{ marginTop: 8 }}>
                {t("verify.learnMoreDialogDescription3")}
              </Typography>
            )}
            <img
              src={
                learnMoreDialogOpen ? VerificationMailPreview : RiskMailPreview
              }
              alt="Learn More"
              style={{ width: "100%", marginTop: 16, borderRadius: 16 }}
            />
          </DialogContent>

          <DialogActions>
            <Tooltip
              title={
                !hasDevices
                  ? t("verify.testNoDevices")
                  : learnMoreRiskDialogOpen
                    ? t("verify.testRiskDevices")
                    : t("verify.testVerifiedDevices")
              }
              placement="right"
            >
              <span>
                <Button
                  variant="contained"
                  disabled={!hasDevices}
                  onClick={() => {
                    handleLearnMoreDialogClose();
                    learnMoreRiskDialogOpen
                      ? SendRiskTestEmail()
                      : SendVerifyTestEmail();
                  }}
                >
                  {t("verify.testYourself")}
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default Awareness;
