import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Typography,
  Alert,
  Divider,
  Button,
  Chip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import "./PoliciesPage.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import XFA_API, {
  Application,
  Organization,
  Policies,
  Role,
} from "../API/XFA_API";

import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import BreadCrumbs from "../General/BreadCrumbs";
import PolicyPage from "./PolicyPage/PolicyPage";
import { Routes, Route } from "react-router-dom";
import NavigationService from "../../utils/NavigationService";
import EmptyState from "../General/EmptyState";
import { getApplicationString, hasApplications } from "./PoliciesUtils";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import editIcon from "../../images/edit-icon.svg";

interface PoliciesPageProps {
  role: Role;
}

interface PoliciesOverviewProps {
  role: Role;
}

const PoliciesPage: React.FC<PoliciesPageProps> = (
  props: PoliciesPageProps,
) => {
  return (
    <Routes>
      <Route path="" element={<PoliciesOverview {...props} />} />
      <Route
        path="new"
        element={
          <PolicyPage
            role={props.role}
            organizationId={props.role.organization.organization_id}
            applications={[]}
          />
        }
      />
      <Route
        path=":policyId"
        element={
          <PolicyPage
            role={props.role}
            organizationId={props.role.organization.organization_id}
            applications={[]}
          />
        }
      />
    </Routes>
  );
};

const PoliciesOverview: React.FC<PoliciesOverviewProps> = (
  props: PoliciesOverviewProps,
) => {
  const { t } = useTranslation();
  const [policies, setPolicies] = React.useState<Policies[] | undefined>(
    undefined,
  );
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const breadcrumbLinks = [
    { label: t("navigation.policies"), href: "/policies" },
  ];
  const [deletePolicyDialog, setDeletePolicyDialog] = React.useState<
    Policies | undefined
  >(undefined);
  const [deletePolicyInProgress, setDeletePolicyInProgress] =
    React.useState(false);
  const [applications, setApplications] = useState<Application[]>();
  const [applicationCount, setApplicationCount] = useState<number>(0);
  const [organization, setOrganization] = useState<Organization | undefined>(
    props.role.organization,
  );

  const getPolicies = useCallback(
    (role: Role) => {
      return XFA_API.getPolicies(role.organization.organization_id).then(
        (result) => {
          if (result) {
            setPolicies(result);
            if (
              role.organization.default_policy_id === undefined &&
              result.length > 0
            ) {
              updateDefaultPolicy(result[0]);
            }
          } else {
            setError(t("policies.RetrieveError"));
          }
        },
      );
    },
    [t],
  );

  const getApplications = useCallback(() => {
    return XFA_API.getApplications(
      props.role.organization.organization_id,
    ).then((applications) => {
      setApplicationCount(applications.length);
      setApplications(applications);
    });
  }, [props.role.organization.organization_id]);

  const refreshOrganization = useCallback(() => {
    return XFA_API.getOrganizationFull(
      props.role.organization.organization_id,
    ).then((organization) => {
      if (organization) {
        props.role.organization = organization;
        setOrganization(organization);
      }
    });
  }, [props.role.organization]);

  const updateDefaultPolicy = useCallback(
    (policy: Policies) => {
      const updatedOrganization: Organization = { ...props.role.organization };
      updatedOrganization.default_policy_id = policy.policy_id;
      XFA_API.updateOrganization(
        props.role.organization.organization_id,
        updatedOrganization,
      ).then(() => {
        props.role.organization.default_policy_id = policy.policy_id;
        refreshOrganization();
      });
    },
    [refreshOrganization, props.role.organization],
  );

  React.useEffect(() => {
    if (props.role) {
      setLoading(true);
      Promise.all([
        refreshOrganization(),
        getPolicies(props.role),
        getApplications(),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(t("policies.RetrieveError"));
        });
    }
  }, [props.role, t]);

  return (
    <div>
      <BreadCrumbs links={breadcrumbLinks} />
      <div style={{ display: "flex" }}>
        <Typography variant="pagetitle" color="primary">
          {t("policies.title")}
        </Typography>
        {policies && policies.length > 0 && (
          <div className="invite-buttons">
            <Button
              variant="contained"
              onClick={() => {
                NavigationService.navigateToPolicy();
              }}
            >
              {t("policies.createPolicy.button")}
            </Button>
          </div>
        )}
      </div>
      <Divider style={{ marginTop: 16 }} />
      {loading ? (
        <div style={{ textAlign: "center", paddingTop: 25 }}>
          <CircularProgress size={30} />
        </div>
      ) : (
        <div className="table w-full" data-cy="policies">
          {policies && policies.length > 0 && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="policies-table"
              >
                <TableHead style={{ background: "var(--color-gray-100)" }}>
                  <TableRow>
                    <TableCell>{t("policies.name")}</TableCell>
                    <TableCell>{t("policies.integrations")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {organization &&
                    policies &&
                    policies.map((policy: Policies) => {
                      return (
                        <TableRow
                          key={policy.policy_id}
                          className="selectableRow"
                        >
                          <TableCell
                            onClick={() =>
                              NavigationService.navigateToPolicy(
                                policy.policy_id,
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div>
                              {policy.name}{" "}
                              {policy.policy_id ===
                                organization.default_policy_id && (
                                <Chip
                                  size="small"
                                  label={"Default"}
                                  color="primary"
                                  style={{ marginLeft: 6 }}
                                />
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              NavigationService.navigateToPolicy(
                                policy.policy_id,
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {getApplicationString(
                              applications ?? [],
                              policy,
                              applicationCount,
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <>
                              <DropdownMenu
                                mode="dropdown"
                                actions={[
                                  {
                                    label: "policies.editPolicy.title",
                                    icon: (
                                      <img
                                        src={editIcon}
                                        alt="editPolicy"
                                        className="icon"
                                      />
                                    ),
                                    onClick: () => {
                                      NavigationService.navigateToPolicy(
                                        policy.policy_id,
                                      );
                                    },
                                  },
                                  {
                                    label: "policies.setDefaultPolicy",
                                    icon: <StarIcon className="icon" />,
                                    disabled:
                                      policy.policy_id ===
                                      props.role.organization.default_policy_id,
                                    onClick: () => {
                                      updateDefaultPolicy(policy);
                                    },
                                  },
                                  {
                                    label: "policies.deletePolicy",
                                    icon: (
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon-delete"
                                      >
                                        <path
                                          d="M15 6.75L13.5037 15.2597C13.3777 15.977 12.7546 16.5 12.0265 16.5H5.97355C5.24534 16.5 4.62232 15.977 4.49622 15.2597L3 6.75"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M15.75 4.5H11.5312M11.5312 4.5V3C11.5312 2.17157 10.8597 1.5 10.0312 1.5H7.96875C7.14032 1.5 6.46875 2.17157 6.46875 3V4.5M11.5312 4.5H6.46875M2.25 4.5H6.46875"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ),
                                    disabled:
                                      policies.length === 1 ||
                                      hasApplications(
                                        applications ?? [],
                                        policy,
                                      ),
                                    disabledTooltip: t(
                                      "policies.deletePolicyTooltip",
                                    ),
                                    delete: true,
                                    onClick: () => {
                                      setDeletePolicyDialog(policy);
                                    },
                                  },
                                ]}
                              />
                            </>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {error && (
                <Alert
                  onClose={() => {
                    setError("");
                  }}
                  severity="error"
                >
                  {error}
                </Alert>
              )}
            </TableContainer>
          )}
          {policies && policies.length === 0 && (
            <EmptyState
              context={"policies"}
              onClick={() => {
                NavigationService.navigateToPolicy();
              }}
            />
          )}
          {deletePolicyDialog && (
            <ConfirmationDialog
              title={t("policies.deletePolicyConfirm")}
              cancelText={t("policies.deletePolicyCancel")}
              confirmText={t("policies.deletePolicyConfirmButton")}
              inProgress={deletePolicyInProgress}
              onCancel={() => {
                setDeletePolicyDialog(undefined);
              }}
              onConfirm={() => {
                setDeletePolicyInProgress(true);
                XFA_API.deletePolicy(
                  props.role.organization.organization_id,
                  deletePolicyDialog.policy_id,
                )
                  .then(() => {
                    setDeletePolicyDialog(undefined);
                    getPolicies(props.role);
                  })
                  .finally(() => {
                    setDeletePolicyInProgress(false);
                  });
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default PoliciesPage;
