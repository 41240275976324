import React from "react";
import AuthStateApp from "./components/Auth/AuthStateApp";
import { DashboardTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";

import "@fontsource/manrope";
import NavigationService from "./utils/NavigationService";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  React.useEffect(() => {
    NavigationService.setNavigate(navigate);
  }, [navigate]);

  return (
    <ThemeProvider theme={DashboardTheme}>
      <div className="App">
        <AuthStateApp />
      </div>
    </ThemeProvider>
  );
}

export default App;
