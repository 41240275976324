import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import XFA_API, { Application, Policies } from "../../API/XFA_API";
import ApplicationCard from "../../Applications/ApplicationCard";
import NavigationService from "../../../utils/NavigationService";
import AddIcon from "@mui/icons-material/Add";
import { applicationPolicy } from "../PoliciesUtils";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";
import DropdownDialog from "../../Dialogs/DropdownDialog";
import { isDiscoveryApplication } from "../../../utils";

interface PolicyIntegrationsComponentProps {
  organizationId: string;
  policyId: string;
  addApplicationToUpdate: (application: Application) => void;
  policyName: string;
}

const PolicyIntegrationsComponent: React.FC<
  PolicyIntegrationsComponentProps
> = ({ organizationId, policyId, addApplicationToUpdate, policyName }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const [policies, setPolicies] = useState<Policies[]>([]);
  const [addPolicyDialog, setAddPolicyDialog] = useState<
    Application | undefined
  >(undefined);
  const [removePolicyDialog, setRemovePolicyDialog] = useState<
    Application | undefined
  >(undefined);
  const [addPolicyInProgress, setAddPolicyInProgress] = useState(false);
  const [removePolicyInProgress, setRemovePolicyInProgress] = useState(false);
  const [previousPolicy, setPreviousPolicy] = useState<string | undefined>();
  const [previousCustomPolicy, setPreviousCustomPolicy] = useState<string>("");
  const [policyInProgress, setPolicyInProgress] = useState<string>("");

  const getApplications = useCallback(() => {
    setLoading(true);
    XFA_API.getApplications(organizationId).then((applications) => {
      const filteredApplications = applications.filter(
        (app) => !isDiscoveryApplication(app),
      );
      setApplications(filteredApplications);
      setLoading(false);
    });
  }, [organizationId]);

  const getPolicies = useCallback(() => {
    setLoading(true);
    XFA_API.getPolicies(organizationId).then((result) => {
      if (result) {
        setPolicies(result);
        setLoading(false);
      }
    });
  }, [organizationId]);

  React.useEffect(() => {
    getApplications();
    getPolicies();
  }, [getApplications, getPolicies]);

  const handleRemoveApplication = (application: Application) => {
    // If the application is already assigned to the policy, ask the user if they want to remove it and add it to another policy
    if (
      application.PolicyID === policyId &&
      policies.find((policy) => policy.policy_id !== policyId)?.name !==
        undefined &&
      !previousCustomPolicy
    ) {
      setRemovePolicyDialog(application);
      // If the application is assigned to the policy and there is only one policy, remove the application from the policy
    } else if (
      application.PolicyID === policyId &&
      policies.find((policy) => policy.policy_id !== policyId)?.name ===
        undefined
    ) {
      application.PolicyID = undefined;
      addApplicationToUpdate(application);
    } else {
      addApplicationToUpdate(application);
    }
  };

  const handleAddApplication = (application: Application) => {
    if (application.PolicyID && application.PolicyID !== policyId) {
      setPreviousPolicy(application.PolicyID);
      if (application.PolicyID === undefined) {
        setPreviousCustomPolicy(application.ApplicationID);
      }
      setAddPolicyDialog(application);
    } else {
      application.PolicyID = policyId;
      setPreviousCustomPolicy(application.ApplicationID);
      addApplicationToUpdate(application);
    }
  };

  const handleConfirmRemove = () => {
    if (removePolicyDialog) {
      removePolicyDialog.PolicyID = previousPolicy
        ? policies.find((policy) => policy.policy_id !== policyId)?.policy_id
        : undefined;
      addApplicationToUpdate(removePolicyDialog);
      setRemovePolicyDialog(undefined);
      setRemovePolicyInProgress(false);
      setPreviousCustomPolicy("");
    }
  };

  const handleConfirmAdd = () => {
    if (addPolicyDialog) {
      addPolicyDialog.PolicyID = policyId;
      addApplicationToUpdate(addPolicyDialog);
      setAddPolicyDialog(undefined);
      setAddPolicyInProgress(false);
      setPreviousCustomPolicy("");
    }
  };

  const handleConfirmChangePolicy = () => {
    if (policyInProgress && removePolicyDialog) {
      removePolicyDialog.PolicyID = policyInProgress;
      addApplicationToUpdate(removePolicyDialog);
      setRemovePolicyDialog(undefined);
      setRemovePolicyInProgress(false);
      setPolicyInProgress("");
      setPreviousCustomPolicy("");
    }
  };

  return (
    <>
      {loading && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
      <FormControl className="testSection">
        <div className="section">
          <div
            style={{
              marginBottom: 24,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="formsectiontitle">
                {t("policies.integrations")}
              </Typography>
              <Typography variant="instructions">
                {t("policies.integrationsDescription")}
              </Typography>
            </div>
          </div>
          <div
            className="applications-grid"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "16px",
            }}
          >
            {applications &&
              applications.map((application: Application) => {
                return (
                  <Grid
                    item
                    key={application.ApplicationID}
                    style={{ padding: 0 }}
                  >
                    <ApplicationCard
                      enabled={application.PolicyID === policyId}
                      default={false}
                      title={application.Name}
                      logo={application.IconUrl}
                      description={applicationPolicy(
                        application,
                        policies,
                        policyId === undefined ? policyName : undefined,
                      )}
                      skipped={false}
                      filtered={false}
                      showStatus={false}
                      disabled={
                        policies.length === 1 &&
                        application.PolicyID === policyId &&
                        previousPolicy === undefined &&
                        previousCustomPolicy !== application.ApplicationID
                      }
                      disabledTooltip={
                        policies.length === 1 &&
                        application.PolicyID === policyId &&
                        previousPolicy === undefined &&
                        previousCustomPolicy !== application.ApplicationID
                          ? t(
                              "policies.editPolicy.editApplicationPolicyTooltip",
                            )
                          : undefined
                      }
                      setEnabled={(value) => {
                        if (value) {
                          handleAddApplication(application);
                        } else {
                          handleRemoveApplication(application);
                        }
                      }}
                      onManage={() => {
                        NavigationService.navigateToApplication(
                          application.ApplicationID,
                        );
                      }}
                      onDelete={undefined}
                    />
                  </Grid>
                );
              })}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => {
                NavigationService.navigateToApplications();
              }}
              disabled={loading}
              style={{
                marginLeft: 0,
                width: "100%",
                borderColor: "var(--color-gray-200)",
              }}
            >
              {t("policies.createPolicy.noIntegrationButton")}
            </Button>
          </div>
        </div>
      </FormControl>

      {removePolicyDialog && policies.length < 3 && (
        <ConfirmationDialog
          title={
            t("policies.editPolicy.removeApplicationPolicy") +
            (policies.find((policy) => policy.policy_id !== policyId)?.name ??
              t("policies.customPolicy"))
          }
          cancelText={t("policies.deletePolicyCancel")}
          confirmText={t("policies.editPolicy.saveButton")}
          inProgress={removePolicyInProgress}
          onCancel={() => setRemovePolicyDialog(undefined)}
          onConfirm={handleConfirmRemove}
        />
      )}
      {addPolicyDialog && (
        <ConfirmationDialog
          title={
            t("policies.editPolicy.addApplicationPolicy") +
            " " +
            (policies.find(
              (policy) => policy.policy_id === addPolicyDialog.PolicyID,
            )?.name ?? t("policies.customPolicy"))
          }
          cancelText={t("policies.deletePolicyCancel")}
          confirmText={t("policies.editPolicy.saveButton")}
          inProgress={addPolicyInProgress}
          onCancel={() => setAddPolicyDialog(undefined)}
          onConfirm={handleConfirmAdd}
        />
      )}
      {removePolicyDialog && policies.length >= 3 && (
        <DropdownDialog
          title={t("policies.editPolicy.changeApplicationPolicy")}
          cancelText={t("policies.deletePolicyCancel")}
          confirmText={t("policies.editPolicy.saveButton")}
          actions={policies.map((policy) => ({
            label: policy.name || "Unnamed Policy",
            value: policy.policy_id,
            onClick: (policyId) => {
              setPolicyInProgress(policyId);
            },
            icon: null,
          }))}
          inProgress={removePolicyInProgress}
          onCancel={() => setRemovePolicyDialog(undefined)}
          onConfirm={handleConfirmChangePolicy}
        />
      )}
    </>
  );
};

export default PolicyIntegrationsComponent;
