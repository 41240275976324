import { Typography } from "@mui/material";
import React from "react";
import { XfaSwitch } from "../../General/XfaSwitch";

const BooleanSetting = (props: {
  label: string;
  explanation: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Typography variant="setting">{props.label}</Typography>
        <Typography variant="instructions">{props.explanation}</Typography>
      </div>
      <div>
        <XfaSwitch
          checked={props.value}
          disabled={props.disabled}
          disableRipple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange(event.target.checked)
          }
        />
      </div>
    </div>
  );
};
export default BooleanSetting;
