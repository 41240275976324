import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Alert} from '@mui/material';
import './Statistics.css';
import {Role} from '../API/XFA_API';

interface StatisticsProps {
  role: Role;
}

const Statistics: React.FC<StatisticsProps> = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Alert severity="info">Not yet available</Alert>
    </div>
  );
};

export default Statistics;
