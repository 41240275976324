import React, { useEffect, useState } from "react";
import {
  AppBar,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import HelpGuideIcon from "../images/helpguide.svg";
import LogoutIcon from "../images/logout.svg";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import RoleSelector from "./RoleSelector";
import logo from "../images/XFA_woordmerk_gray900.svg";
import NavItems from "./NavItems";
import { Role } from "./API/XFA_API";
import NavigationService from "../utils/NavigationService";

interface NavBarProps {
  user: any;
  drawerWidth: string;
  activeRole: Role | undefined;
  inactiveRoles: Role[] | undefined;
  changeRole: (id: string) => void;
  refreshRoles: (id?: string) => void;
  isAdmin: () => boolean;
  onSignOut: () => void;
  handleLanguageButtonClick: () => void;
}

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState<string | undefined>(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:991px)");

  useEffect(() => {
    const path = location.pathname;
    const routes = [
      "overview",
      "devices",
      "organizations",
      "settings",
      "policies",
      "devices-old",
      "status",
      "support",
      "applications",
      "discovery",
      "awareness",
    ];
    const currentPage =
      routes.find((route) => path.includes(route)) ?? "overview";
    setPage(currentPage);
  }, [location]);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className="homeAppBar"
        sx={{
          width: isMobile ? "100%" : `calc(100% - ${props.drawerWidth}px)`,
          ml: isMobile ? 0 : `${props.drawerWidth}px`,
        }}
      >
        <Toolbar>
          {isMobile && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                onClick={() => {
                  NavigationService.navigateToHome();
                }}
              >
                <img
                  alt="logo"
                  src={logo}
                  style={{ height: 24, margin: "12px 12px 12px 0px" }}
                />
              </div>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
                sx={{ mr: 0 }}
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        className="homeNavigation"
        sx={{
          width: props.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            background: "var(--color-gray-100)",
            border: "none",
            width: isMobile ? "80%" : props.drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? drawerOpen : true}
        onClose={toggleDrawer}
      >
        <div style={{ borderBottom: "1px solid var(--color-gray-200)" }}>
          <img alt="logo" src={logo} style={{ height: 32, margin: 24 }} />
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {props.activeRole && (
            <>
              <NavItems
                page={page}
                setPage={setPage}
                activeRole={props.activeRole}
                onItemClick={() => {
                  setDrawerOpen(false);
                }}
              />
              <div className="footer">
                <List style={{ marginTop: 8 }}>
                  <ListItemButton
                    key="settings"
                    selected={page === "settings"}
                    component={Link}
                    to="/settings"
                    onClick={() => {
                      setPage("settings");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.settings")} />
                  </ListItemButton>
                  <ListItemButton
                    key="help"
                    component={Link}
                    to="https://docs.xfa.tech"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img className="navigationIcon" src={HelpGuideIcon} />
                    </ListItemIcon>
                    <ListItemText primary={t("navigation.help")} />
                  </ListItemButton>
                  <ListItemButton
                    key="language"
                    onClick={() => {
                      setDrawerOpen(false);
                      props.handleLanguageButtonClick();
                    }}
                  >
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${t("navigation.language.navTitle")}: ${i18n.language.toUpperCase()}`}
                    />
                  </ListItemButton>
                </List>
                <div style={{ height: 8 }}></div>
                <RoleSelector
                  activeRole={props.activeRole}
                  inactiveRoles={props.inactiveRoles}
                  changeRole={props.changeRole}
                  refreshRoles={props.refreshRoles}
                />
                <ListItemButton
                  style={{
                    border: "1px solid var(--color-gray-200)",
                    color: "var(--color-gray-500)",
                    fontSize: 12,
                    fontWeight: 500,
                    height: 64,
                  }}
                >
                  <span
                    style={{
                      width: "90%",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {props.user.email}
                  </span>
                  <ListItemIcon style={{ margin: 0 }}>
                    <Button
                      style={{ minWidth: "auto", padding: 8 }}
                      onClick={props.onSignOut}
                      id="logout-button"
                    >
                      <img
                        src={LogoutIcon}
                        style={{ height: "16px!important" }}
                      />
                    </Button>
                  </ListItemIcon>
                </ListItemButton>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
